.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  



.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   


.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.tileContainer{
    display: flex;
    flex-direction: column;
    height: 91.3vh;
}
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.iFrameStyle{
    height: 100%;
    width: 100%;
}

iframe{
    height: 96vh;
}

.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   
.Embed-container {
    height: 89vh;
    display: flex;
    justify-content: center;
}   

.dashboard-container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  transition: all 0.3s ease;
}
.header {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:#BE0028;
  color: white;
  padding: 10px 20px;
}

.sidebar {
  background-color: #f7f7f7;
  padding: 20px;
  border-right: 1px solid #c5c5c5;
  width: 500px;
  overflow-y: auto;
  transition: width 0.3s ease;
}
.sidebar.hidden {
  width: 0;
  padding: 0;
  overflow: hidden;
  border: none;
}
.sidebar.visible {
  width: 20vw;
}
.sidebar-toggle-btn {
  position: absolute;
  top: 4.65rem !important;
  background-color: white;
  color: black;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  z-index: 9998;
}
.left-13{
  left: 18.1vw;
  transition: left 0.5s ease;
}
.left-1{
  left: 1vw;
  transition: left 0.5s ease;
}

.sidebar-toggle-btn:hover {
  background-color: white;
}
.sidebar.hidden ~ .content {
  margin-left: 0;
}

.filter-group {
  margin-bottom: 15px;
  width: 100%;
}

.filter-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.filter-group select {
  width: 100%;
  padding: 8px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  outline: none;
}

.filter-group select:hover {
  border-color: #007bff;
}

.filter-group select:focus {
  border-color: #0056b3;
  box-shadow: 0 0 3px #0056b3;
}

.date-picker {
  width: 100%;
  padding: 8px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  outline: none;
}

.date-picker:hover {
  border-color: #007bff;
}

.date-picker:focus {
  border-color: #0056b3;
  box-shadow: 0 0 3px #0056b3;
}

.content {
  flex-grow: 1;
  width:85vw;
  padding: 20px;
  background-color: #ffffff;
  overflow: hidden;
  transition: margin-left 0.3s ease; 
}

.column-selector {
  padding: 20px;
  max-width: 100%;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.multiselect-container {
  width: 20%;
  font-size: 12px;
}

.selectColumnsText {
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.custom-multiselect {
  position: relative;
}
.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root
{ 
  padding-left: -1% !important;

}
.custom-multiselect::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  transform: translateY(-50%);
  pointer-events: none;
}

.data-display {
  margin-top: 20px;
  overflow-x: auto;
}

.multiSelectContainer input {
  margin-top: 0px !important;
}


.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  border: 1px solid black;
}

.clear-filters-btn {
  background-color:  #BE0028;
  color: #ffffff;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}
#dropdown-expt {
  all:unset;
}
.tss-qbo1l6-MUIDataTableToolbar-actions{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse !important;
}
.tss-tlx3x1-MUIDataTableToolbar-root{
  flex-direction: row-reverse !important;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
  margin-bottom: 0px !important;
}
.MuiTableCell-root{
  padding: 0.5rem !important;
  border: 0.5px solid rgb(239, 228, 228) !important;
}
.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
  background-color: #BE0028 !important;
  color: white !important;
}
.tss-bd2lw8-MUIDataTableHeadCell-sortActive {
  color: white !important;
}
.MuiTableSortLabel-icon{
  color: white !important;
}
.MuiFormControl-root{
  width: 100%;
}
.MuiSelect-root .MuiInputBase-root .MuiOutlinedInput-root .MuiOutlinedInput-input {
  width: 100%;
  padding: 8px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  outline: none;
}
.MuiOutlinedInput-root, .MuiInputBase-formControl{
  width: 100%;
}
.loadingBox{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);
  display:flex;
  justify-content:center;
  align-items:center;
  z-index:9999
}
.MuiOutlinedInput-root.MuiInputBase-sizeSmall{
  width: 100%;
  padding: 10px !important;
  font-size: 12px;
  border-radius: 4px;
  background-color: #fff !important;
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
}
.MuiAutocomplete-root .MuiAutocomplete-tag {
  margin: 2px;
  max-width: calc(100% - 30px) !important;
}
.MuiPopper-root{
  width: auto !important;
}
.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  width: 100%;
}
.align-left{
  margin-left: auto;
}
.MuiDataGrid-columnHeader{
  background-color : #BE0028 !important;
  color: 'white' !important;
  font-weight: 'bold' !important;
  border: 1px solid #ccc !important;
  height: 2.5rem !important;
  padding: 0.5rem !important;
}
.MuiDataGrid-column{
  border: 1;
  border-color: 'black';
  height: 2.5rem !important;
  padding: 0 !important;
}


.MuiButtonBase-root{
  min-width: 0px !important;
}
.MuiButton-icon.MuiButton-startIcon.MuiButton-iconSizeSmall{
  margin-right: 0px !important;
  color: gray;
}
.MuiDataGrid-toolbarContainer{
  padding: 0.5rem !important;
}
.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor{
  min-height: 0px !important;
  border-top-right-radius:0px !important;
  border-top-left-radius:0px !important;
}
.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor,
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall{
  color: white;
}
.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor{
  height: auto;
  min-height: 20%;
}
.MuiDataGrid-overlay, .MuiDataGrid-overlayWrapperInner{
  height: 20% !important;
  margin-top: 1rem !important;
}
.MuiDataGrid-overlayWrapper{
  height: auto !important;
}
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-withBorderColor{
  height: auto !important;
  padding: 0.5rem !important;
  border: 0.5px solid !important;
  border-color: rgb(240, 234, 234) !important;
}
.MuiDataGrid-panelWrapper{
  height: 20rem;
}
.MuiSwitch-root .MuiSwitch-thumb {
  display: none !important;
}

.MuiSwitch-root .MuiSwitch-track {
  display: none !important;
}

.MuiSwitch-root .MuiTouchRipple-root {
  display: none !important;
}

.MuiSwitch-root .MuiSwitch-input {
  position: absolute !important; 
  width: 18px; 
  height: 18px;
  opacity : 1!important;
  left: 0 !important;
  top: 0 !important;
  margin: 0;
  z-index: 1; 
  transform: none; 
  background-color: transparent;
  border: 2px solid #000; 
  border-radius: 3px;
}

.MuiSwitch-root.MuiSwitch-input:checked {
  background-color: #0077B6; 
  border-color: #0077B6;
}

.MuiSwitch-root.MuiSwitch-input:focus {
  outline: none !important; 
  border-color: #0077B6; 
}
.MuiSwitch-root .MuiButtonBase-root {
  pointer-events: none !important; 
}
.MuiSwitch-switchBase.Mui-checked{
  transform: none !important;
}
.MuiSwitch-root {
  width: 25px !important;
}
.MuiDataGrid-withBorderColor {
  border-color: #fff !important;
}
.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutter
{
  display: none !important;
} 
.MuiButton-startIcon > :nth-of-type(1)
{
  font-size: 200% !important;
}
.MuiButton-icon:hover.MuiButton-startIcon:hover.MuiButton-iconSizeSmall:hover{
  color: '#0077B6'!important;
}
.custombutton
{
    padding:1%;
    font-weight: 750;
}
.demo{
    font-size: small !important;
    padding: 0.5rem !important;
    border: 0.5px solid #0077B6 !important;
    max-width: 10 !important ;
}
.container1
{
    padding-left : 2% ;
    padding-right : 2% ;
    padding-bottom : 2% ;
}
.header1    
{
    font-size: medium !important;
    padding: 0.5rem !important;
    border: 0.5px solid #ffff !important;
    color: white !important;
    background-color: #0077B6;
}
.MuiTableContainer-root.container1
{
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.logo {
  height: 3vw;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

