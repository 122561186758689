.custombutton
{
    padding:1%;
    font-weight: 750;
}
.demo{
    font-size: small !important;
    padding: 0.5rem !important;
    border: 0.5px solid #0077B6 !important;
    max-width: 10 !important ;
}
.container1
{
    padding-left : 2% ;
    padding-right : 2% ;
    padding-bottom : 2% ;
}
.header1    
{
    font-size: medium !important;
    padding: 0.5rem !important;
    border: 0.5px solid #ffff !important;
    color: white !important;
    background-color: #0077B6;
}
.MuiTableContainer-root.container1
{
    max-width: fit-content;
}